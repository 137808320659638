
<img src="assets/img/Sarcodya_our_work6.jpg" class="img-funding-top" alt="image1" width="100%" height="500">



<img src="assets/img/donate.png" alt="donate" width="100%" />

<div class="donate-details">
    <h2 class="heading1">Online Payment, Post and NEFT or RTGS.</h2>
    <p>if You want to donate our institute, it is more convenient for you, you can donate
         online donation like (Phonpe, Gpay, Paytm etc). <br /> </p>
         <P>Please click this button For Online Donation.</P>
         <button mat-raised-button color="primary">ONLINE DONATION</button>
         <br><br>
    <p>Our opening hours are 24*7.</p>
    <br />
    <div class="row">
        <div class="col-md-6">

            <h2>You can also send us a cheque via post to:</h2>

            <h6> SARVODAY FOUNDATION<br />
                HEAD OFFICE:<br />
                Plot no:420, village:dharampur<br />
                GAYA, BIHAR-824201<br />
                <br />
                Regional office:<br />
                Flat no:80; Building No:05<br />
                Sector-07, Antop Hill<br />
                Mumbai-400037<br />

            </h6>
        </div>
        <div class="col-md-6">

            <h2>You can also send us donation via NEFT or RTGS to:</h2>
            <h6>
                Current Account No: 38917726225<br>
                RTGS/NEFT IFSC CODE: SBIN0014316<br>
                Bank branch: DOBHI, GAYA<br>
                Bank: State Bank Of India<br>
                <br />
                <br>
                PAN: AAYTS4722M
            </h6>
        </div>
    </div>
    <hr>
    <div>
        <h2 class="heading1">FUNDRAISING:</h2>
        <p>You can start raising funds campaign with an initiative to gather voluntary contributions by involving your
            friends and family. The money raised in these campaigns will be used to provide educational material to
            children and food in mid-day meal from
            Sarvoday siksha abhiyan. Further it will help in arranging equipments helpful in skilling millions of people
            and creating self employment opportunities for them.
            <br>
            You can take up an online fundraising campaign on the website. Once you pick a 
            fundraising idea and complete
            the required formalities, your campaign will be live. You can then share it on
             your social media channels to
            create awareness regarding the same, and get people to donate to the campaign.
            <br>
            • You can add celebration of special occasions with us – birthdays, 
            anniversaries and the like – by creating
            a fundraising campaign.
            <br>
            • You can also fix upon a charity fundraising amount you want to raise.</p>
        </div>
            <div class="start-campaign">
          <h2>Start a Campaign:</h2> 
         <p>Sign in or register and create an online fundraising campaign by filling in
              the required details. Create
          Awareness: Once the campaign has been created, share the link on Facebook, 
          Twitter and other social media
         channels And let your friends know.</p>
        </div>
        <div>
        <h2>Raise Funds:</h2>
        <p>Tell your friends and family that they can support Sarvoday Foundation by 
            contributing to your purposeful
            campaign.</p>
        </div>
        <div>
        <h2>Make an Impact:</h2>
       <p>Know that all the funds raised through your campaign will be utilised 
           to provide nutritious meals to school
            children every day!</p>
        </div>
</div>

