
<!-- IMAGE TOP -->
<section class="about">
    <img src="assets/img/Sarvodya-about1.jpg" alt="" width="100%" height="auto">
    <div class="overlay">
        ABOUT US
    </div>
</section>
<br>

<!-- Who WE ARE -->
<section class="about1">
    <div class="container count_lg">
        <h3 class="ab">WHO WE ARE</h3>
            <p>SARVODAY FOUNDATION FOR EDUCATION  & SKILL DEVELOPMENT is trust registered with Government of Bihar. Started in a small village called DHARAMPUR in district GAYA it has all its members and trustees drawn from different section of society who have promised to organise themselves to improve livelihood & standard of living of village. The trust is run by annual contributions made my working professionals of the village and donations it receives from various noble souls like you.</p>
            
    </div>    
</section>
<br>

<!-- What we are doing -->

<div class="sec_pad">
    <div class="container count_lg">
        <div class="row justify-content-center">
            <div class="col-xl-6">
                <div class="section-tittle text-center m-8">
                    <span>What we are doing</span>
                    <h2>We Are Also Working On this Matter</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 card">
                <div class="cat-icon">
                    <i class="fa fa-cogs mr" aria-hidden="true"></i>
                </div>
                <h3>Clean Water</h3>
                <p>The sea freight service has grown conside rably in recent years. We spend timetting to know your processes to.</p>
            </div>
            <div class="col-md-4 card mul active">
                <div class="cat-icon">
                    <i class="fa fa-cogs mr" aria-hidden="true"></i>
                </div>
                <h3>Education. Clothing</h3>
                <p>We provide clean clothes for poor student. We are also provide the school drees for student. </p>          
                </div>
            <div class="col-md-4 card">
                <div class="cat-icon">
                    <i class="fa fa-cog mr" aria-hidden="true"></i>
                </div>
                <h3>Agriculture & Food</h3>
                <p>In order to help the communities we have created a group of farmers who donate agricultural product to food banks.</p>
            </div>
        </div>
    </div>
</div>

<br>
<!-- We Are In A Mission To Help The Helpless -->
<section class="about2">
    <div class="container count_lg">
        <h3 class="ab">We Are In A Mission To Help <br>The Helpless</h3>
        <div class="row mar">
            <div class="col-md-6">
                <p>This objective of the trust is aimed at ensuring free of cost compulsory primary education to all the children’s from age 3yrs till they achieve the age of 10yrs. You can sponsor a child for their education. They are provided with all books, notebooks, school dress and food as mid-day meal. These children coming from very deprived families who in lack of food or source of livelihood of parents are forced to work resulting in harsh child labour conditions or beg on road or village. Education being a fundament right Is of paramount importance which can transform their fate. To save their future the trust organise their classes on rented premises and provide them with food and care facilities. The trust is trying to raise funds with aid of administration so that various government schemes directed toward child  welfare can be utilized to their full potential. </p>
            </div>
            <div class="col-md-6">
                <img src="assets/img/Sarvodya-about3.jpg" alt="" width="100%" height="300px">
            </div>
        </div>
        <div class="row mar">
            <div class="col-md-6">
                <img src="assets/img/sarvodya-about2.jpg" alt="" width="100%" height="300px">
            </div>
            <div class="col-md-6">
                <p>The trust is also engaged in skilling people in different works such as pottery making, stiching , pickle making, horticulture dairy farming, security, computer and digital training which has started bearing fruits. Village economy is dependent on agriculture which being dependent on monsoon is seasonal in nature which results in cyclic unemployment. Recent home-coming of migrant workers due to countrywide lockdown has enhanced the problem of employment and livelihood. So in order to ensure. </p>
            </div>
        </div>
    </div>
</section>

<br>
<!-- Feadback Happy Clients -->
<section class="about3">
    <div class="container count_lg">
        <h2 class="text-center happ">Happy Clients & Feedbacks</h2>
    </div>
    <div class="container text-center">
        <div id="demo" class="carousel slide" data-ride="carousel">

            <!-- Indicators -->
            <ul class="carousel-indicators">
              <li data-target="#demo" data-slide-to="0" class="active"></li>
              <li data-target="#demo" data-slide-to="1"></li>
              <li data-target="#demo" data-slide-to="2"></li>
            </ul>
            <!-- The slideshow -->
            <div class="carousel-inner container">
              <div class="carousel-item active">
                  <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href=""><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_01.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Sara</h1>
                              <h2>Businesswoman</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                              <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>  </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href=""><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_02.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Ricky</h1>
                              <h2>Father</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                              <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>
                            </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href=""><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_03.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Somya</h1>
                              <h2>Businesswoman</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                              <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>
                            </div>
                      </div>
                  </div>
              </div>
              <div class="carousel-item">
                  <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href=""><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_01.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Sara</h1>
                              <h2>Businesswoman</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                              <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>
                            </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href=""><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_02.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Ricky</h1>
                              <h2>Father</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                              <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>
                            </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href=""><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_03.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Somya</h1>
                              <h2>Businesswoman</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                              <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>
                            </div>
                      </div>
                  </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href=""><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_01.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Sara</h1>
                              <h2>Businesswoman</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                            <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>
                            </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href=""><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_02.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Ricky</h1>
                              <h2>Father</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                              <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>
                            </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                          <div class="box">
                              <a href="#"><img src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_03.png" class="img-fluidi img-thumbnail"></a>
                              <h1>Somya</h1>
                              <h2>Businesswoman</h2>
                              <p class="m-4">Far far away, behind the word mountains, far from the countries Vokalia</p>
                              <p class="rate">
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                                <span class="fa fa-star"></span>
                            </p>
                            </div>
                      </div>
                  </div>
              </div>
            </div>
            <!-- Left and right controls -->
            <a class="carousel-control-prev" href="#demo" data-slide="prev"></a>
            <a class="carousel-control-next" href="#demo" data-slide="next"></a>
          </div>
    </div>
</section>


  