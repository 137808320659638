<section class="outreach">
    <img src="assets/img/Sarvodya-about1.jpg" alt="" width="100%" height="auto">
    <div class="overlay">
        OUT REACH
    </div>
</section>
<br>

<section class="outreach1">
    <div class="container count_lg">
        <h3 class="ab">OUT REACH</h3>
        <P>With a total expense of 4,00,000 we have provide free education to nearly 85 students between age 3yrs to 8 yrs along with providing them books, notebook and mid-day meal.</P>
    </div>    
</section>
<br>

<section class="outreach2 o_r">
    <div class="container count_lg">
        <div class="row paad">
            <div class="col-md-6 para2">
                <p style="margin-top: 30px; color: #38046a;">Further nearly 50 women have been trained in stitching work, while 12 men have been trained in electric bulb assembling and pottery making, car driving.</p>
                <p style="color: #38046a;">We are looking for further progress in the coming year with growing contribution from noble soul like you.</p>
            </div>
            <div class="col-md-6">
                <img src="assets/img/Sarvodya_our_work4.jpg" alt="" width="100%" height="300px">
            </div>
        </div>
    </div>
</section>