<!-- playsinline="playsinline" autoplay="autoplay" loop="loop" -->
<div class="site-logo__shape">
  <svg
    class="shape"
    viewBox="0 0 2540 1296"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M564,0 L0,0 L0,1296 L2540,1296 L2540,888.421875 L1894.7268,1245.22723 C1762.21001,1316.92002 1595.33264,1310.59436 1469.90089,1229.12029 L725.662132,745.707879 C624.180695,679.791748 564,573.649453 564,460.574557 L564,0 Z"
      fill="#FFFFFF"
    ></path>
    <path
      d="M43.6494457,0 L387.125946,0 L564.360477,104.328027 L564.360477,459.204972 C564.360477,572.279868 624.541172,678.422163 726.022609,744.338294 L840.019531,818.383789 L306.597261,1138.3041 C241.709728,1177.08089 159.997363,1173.65951 98.5790545,1129.59227 L8.17124146e-14,1058.86349 L0,31.3177319 L43.6494457,0 Z"
      class="fill-grey"
    ></path>
  </svg>
</div>
<div class="shape-l ma-1">
  <svg
    width="1920px"
    height="1690px"
    viewBox="0 0 1920 1690"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="fill-yellow"
      d="M107.031481,400.759434 C39.8437707,448.856201 0,526.310447 0,608.818959 L0,1064.32167 C0,1146.83241 39.8437707,1224.28443 107.031481,1272.38342 L599.768862,1625.1294 C682.813336,1684.58102 793.297661,1689.19685 881.032916,1636.88263 L1795.00615,1091.9165 C1872.53008,1045.69144 1920,962.212577 1920,872.099296 L1920,801.041336 C1920,710.930281 1872.53008,627.451421 1795.00615,581.226357 L881.032916,36.2602278 C793.297661,-16.0539923 682.813336,-11.4381627 599.768862,48.0134566 L107.031481,400.759434 Z"
    ></path>
  </svg>
</div>
<div class="shape-layer z-1 ma-1">
  <svg
    width="1920px"
    height="1690px"
    viewBox="0 0 1920 1690"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="fill-yellow"
      d="M107.031481,400.759434 C39.8437707,448.856201 0,526.310447 0,608.818959 L0,1064.32167 C0,1146.83241 39.8437707,1224.28443 107.031481,1272.38342 L599.768862,1625.1294 C682.813336,1684.58102 793.297661,1689.19685 881.032916,1636.88263 L1795.00615,1091.9165 C1872.53008,1045.69144 1920,962.212577 1920,872.099296 L1920,801.041336 C1920,710.930281 1872.53008,627.451421 1795.00615,581.226357 L881.032916,36.2602278 C793.297661,-16.0539923 682.813336,-11.4381627 599.768862,48.0134566 L107.031481,400.759434 Z"
    ></path>
  </svg>
</div>

<!-- ################ video and content %%%%%%%%%%%%%%%%%%%%%%%%%%-->
<section>
  <div class="overlay-wcs"></div>
  <video autoplay loop controls>
    <source src="assets/video/home-video2.mp4" type="video/mp4" />
    <source src="assets/video/home-video2.ogg" type="video/ogg" />
  </video>
  <div class="container h-100">
    <div class="d-flex h-100 text-center align-items-center">
      <div class="w-100 text-white">
        <h1>SARVODAY FOUNDATION FOR</h1>
        <p class="lead mb-0">
          EDUCATING THE UNEDUCATED<br />
          FEEDING THE HUNGRY CHILDHOOD<br />
          SKILLING THE UNSKILLED
        </p>
      </div>
    </div>
  </div>
</section>

<!-- ################ WELCOME TO SARVODAY FOUNDATION %%%%%%%%%%%%%%%%%%%%%%%%%%-->
<br /><br />
<div class="section-2">
  <div class="row cont">
    <div class="col-md-6">
      <p class="small-heading">WELCOME TO SARVODAY FOUNDATION</p>
      <h1 class="heading1 title-container underline is-in">
        We are here to help everyone in need
      </h1>
    </div>
    <div class="col-md-6">
      <p>
        SARVODAY FOUNDATION is a social impact NGO and strategic partner,
        specializing in literacy. We Are Working for
        <a href="#">
          Poor Children and nonprofits, foundations and purpose-driven NGO</a
        >.
      </p>

      <p>
        It’s easy to get so wrapped up in fixing your own life that you forget
        about helping others. And while it’s important to put yourself first
        sometimes, it’s even more important to help others whenever you can.
      </p>

      <p>
        “Community service gives me a valuable opportunity to walk into a
        different community that is less familiar to me but just as colorful and
        most importantly, in need.”
      </p>
      <p>
        <a href="#" target="self" class="btn">
          <span class="btn_label">View our Work</span></a
        >
      </p>
    </div>
  </div>
</div>

<!-- ################ Following are some of the bare necessities that are facilitated vide Freedom
             Through Education %%%%%%%%%%%%%%%%%%%%%%%%%%-->
<div class="paralax_page">
  <div class="layer1 m-21">
    <svg
      width="1920px"
      height="1690px"
      viewBox="0 0 1920 1690"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="fill-red"
        d="M107.031481,400.759434 C39.8437707,448.856201 0,526.310447 0,608.818959 L0,1064.32167 C0,1146.83241 39.8437707,1224.28443 107.031481,1272.38342 L599.768862,1625.1294 C682.813336,1684.58102 793.297661,1689.19685 881.032916,1636.88263 L1795.00615,1091.9165 C1872.53008,1045.69144 1920,962.212577 1920,872.099296 L1920,801.041336 C1920,710.930281 1872.53008,627.451421 1795.00615,581.226357 L881.032916,36.2602278 C793.297661,-16.0539923 682.813336,-11.4381627 599.768862,48.0134566 L107.031481,400.759434 Z"
      ></path>
    </svg>
  </div>
  <div class="container container-lg">
    <div class="row w_2">
      <div class="g_item col-sm-6 col-md-6 w_1">
        <img
          src="/assets/img/Sarvodya2.jpg"
          alt="SARODAY FOUNDATION"
          width="100%"
          height="460"
        />
      </div>
      <div class="g-item col-sm-6 col-md-6">
        <h2 class="h2-lg is-in">
          <div class="underline">
            Following are some of the bare necessities that are facilitated vide
            Freedom Through Education
          </div>
        </h2>
        <div class="mb-0 lead">
          <ul class="list-split list-styled">
            <li>Build Quality School Buildings</li>
            <li>Deep Tube-wells for Sanitation</li>
            <li>Build Toilets</li>
            <li>Lights & Fans</li>
            <li>Classroom Furniture</li>
            <li>Science Laboratories</li>
            <li>Filtered Drinking Water System</li>
            <li>Library Rooms</li>
            <li>Computer Rooms</li>
          </ul>
        </div>
        <p style="margin-top: 0rem; float: right">
          <a href="#" target="self" class="btn"
            ><span class="btn_label">View our Services</span></a
          >
        </p>
      </div>
    </div>
  </div>
</div>

<!-- ################ JOIN OUR NEWSLATTER %%%%%%%%%%%%%%%%%%%%%%%%%%-->
<div class="section4">
  <div class="heading-content">
    <p>Our Cases You Can See</p>
    <h1 class="heading-section4 underline4 is-in">
      Explore Our Latest Causes That We Works
    </h1>
  </div>
  <div class="row three-cart">
    <div class="col-md-4 col-sm-4">
      <mat-card class="example-card">
        <img
          mat-card-image
          src="assets/img/Sarvodya1.jpg"
          height="350"
          alt="education"
        />
        <mat-card-content>
          <div class="cases-caption">
            <h3 class="heading-cart">
              Ensure Education For Every Poor Children
            </h3>

            <div class="single-skill mb-15">
              <div class="progress">
                <div
                  class="progress-bar progress-bar-striped active"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 70%"
                >
                  70%
                </div>
              </div>
            </div>
            <br />
            <div class="prices d-flex justify-content-between">
              <p>Raised:<span> $20,000</span></p>
              <p>Goal:<span> $35,000</span></p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-4 col-sm-4">
      <mat-card class="example-card">
        <img
          mat-card-image
          src="assets/img/Sarvodya3.jpg"
          height="350"
          alt="education"
        />
        <mat-card-content>
          <div class="cases-caption">
            <h3 class="heading-cart">Providing Dress For The Children</h3>

            <div class="single-skill mb-15">
              <div class="progress">
                <div
                  class="progress-bar progress-bar-striped active"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 70%"
                >
                  70%
                </div>
              </div>
            </div>
            <br />
            <div class="prices d-flex justify-content-between">
              <p>Raised:<span> $20,000</span></p>
              <p>Goal:<span> $35,000</span></p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-4 col-sm-4">
      <mat-card class="example-card">
        <img
          mat-card-image
          src="assets/img/food.jpg"
          height="350"
          alt="education"
        />
        <mat-card-content>
          <div class="cases-caption">
            <h3 class="heading-cart">
              Providing Healthy Food For The Children
            </h3>

            <div class="single-skill mb-15">
              <div class="progress">
                <div
                  class="progress-bar progress-bar-striped active"
                  role="progressbar"
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 70%"
                >
                  70%
                </div>
              </div>
            </div>
            <br />
            <div class="prices d-flex justify-content-between">
              <p>Raised:<span> $20,000</span></p>
              <p>Goal:<span> $35,000</span></p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<br />
<!-- ################ OUR Voulunteer %%%%%%%%%%%%%%%%%%%%%%%%%%-->

<section id="team" class="pb-5">
  <div class="container">
    <h5 class="section-title h1">OUR VALUNTEER</h5>
    <div class="row">
      <!-- Team member -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="image-flip">
          <div class="mainflip flip-0">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_01.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">SARA</h4>
                  <p class="card-text">
                    “The unselfish effort to bring cheer to others will be the
                    beginning of a happier life for ourselves.”
                  </p>
                  <a
                    href="https://www.fiverr.com/share/qb8D02"
                    class="btn btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="backside">
              <div class="card">
                <div class="card-body text-center mt-4">
                  <h4 class="card-title">Sara</h4>
                  <p class="card-text">
                    “The unselfish effort to bring cheer to others will be the
                    beginning of a happier life for ourselves.”
                  </p>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-skype"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-google"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./Team member -->
      <!-- Team member -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_02.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Ricky</h4>
                  <p class="card-text">
                    “Volunteers don’t get paid, not because they’re worthless,
                    but because they’re priceless.”
                  </p>
                  <a
                    href="https://www.fiverr.com/share/qb8D02"
                    class="btn btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="backside">
              <div class="card">
                <div class="card-body text-center mt-4">
                  <h4 class="card-title">Ricky</h4>
                  <p class="card-text">
                    “Volunteers don’t get paid, not because they’re worthless,
                    but because they’re priceless.”
                  </p>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-skype"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-google"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./Team member -->
      <!-- Team member -->
      <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_03.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Somya</h4>
                  <p class="card-text">
                    “Remember that the happiest people are not those getting
                    more, but those giving more.”
                  </p>
                  <a
                    href="https://www.fiverr.com/share/qb8D02"
                    class="btn btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="backside">
              <div class="card">
                <div class="card-body text-center mt-4">
                  <h4 class="card-title">Somya</h4>
                  <p class="card-text">
                    “Remember that the happiest people are not those getting
                    more, but those giving more.”
                  </p>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-skype"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a
                        class="social-icon text-xs-center"
                        target="_blank"
                        href="https://www.fiverr.com/share/qb8D02"
                      >
                        <i class="fa fa-google"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./Team member -->
    </div>
  </div>
</section>

<div class="join--us">
  <div class="layer3 m-31 j1-2">
    <svg
      width="1920px"
      height="1690px"
      viewBox="0 0 1920 1690"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="fill-yellow"
        d="M107.031481,400.759434 C39.8437707,448.856201 0,526.310447 0,608.818959 L0,1064.32167 C0,1146.83241 39.8437707,1224.28443 107.031481,1272.38342 L599.768862,1625.1294 C682.813336,1684.58102 793.297661,1689.19685 881.032916,1636.88263 L1795.00615,1091.9165 C1872.53008,1045.69144 1920,962.212577 1920,872.099296 L1920,801.041336 C1920,710.930281 1872.53008,627.451421 1795.00615,581.226357 L881.032916,36.2602278 C793.297661,-16.0539923 682.813336,-11.4381627 599.768862,48.0134566 L107.031481,400.759434 Z"
      ></path>
    </svg>
  </div>
  <div class="join-ext j-1">
    <div class="row">
      <div class="col-md-8">
        <h2>Best Way to Make a Difference in the Lives of Others</h2>
      </div>
      <div class="col-md-4">
        <a href="#" target="self" class="btn">
          <span class="btn_label">BECAME A VALUNTEER</span></a
        >
      </div>
    </div>
  </div>
</div>
