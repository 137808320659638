<!-- Footer -->
<footer class="page-footer font-small mdb-color pt-4">
  <!-- Footer Links -->
  <div class="container text-center text-md-left">
    <!-- Footer links -->
    <div class="row text-center text-md-left mt-3 pb-3">
      <!-- Grid column -->
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
        <img src="assets/img/logo-main.png" height="100px" width="160px" class="avatar-01" />
       
        <p>
          “You may not have saved a lot of money in your life, but if you have
          saved a lot of heartaches for other folks, you are a pretty rich man.”
        </p>
        <h3 class="text-uppercase mb-4 font-weight-bold">
          Akshay Patra (Founder)
        </h3>
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-md-none" />

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
        <h3 class="text-uppercase mb-4 font-weight-bold">Our Moto</h3>
        <p>
          <a href="#!">Litracy For Poor Student</a>
        </p>
        <p>
          <a href="#!">Provide Food For Poor Student</a>
        </p>
        <p>
          <a href="#!">Provide Dress For Poor Student</a>
        </p>
        <p>
            <a href="#!">Provide Pure Water For Poor Student</a>
          </p>
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-md-none" />

      <!-- Grid column -->
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
        <h3 class="text-uppercase mb-4 font-weight-bold">Useful links</h3>
        <p>
          <a routerLink="Home">Home</a>
        </p>
        <p>
          <a routerLink="about">About Us</a>
        </p>
        <p>
          <a routerLink="ourwork">Our Work</a>
        </p>
        <p>
          <a routerLink="funding">Funding</a>
        </p>
        <p>
          <a routerLink="gallery">Gallery</a>
        </p>

        <p>
          <a routerLink="contactUs">Contact Us</a>
        </p>
      </div>

      <!-- Grid column -->
      <hr class="w-100 clearfix d-md-none" />

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
        <h3 class="text-uppercase mb-4 font-weight-bold">Contact</h3>
        <p><i class="fas fa-home mr-3"></i> Plot no:420, village:dharampur, GAYA, BIHAR-824201</p>
        <p><i class="fas fa-envelope mr-3"></i> info@gmail.com</p>
        <p><i class="fas fa-phone mr-3"></i> + 01 234 567 88</p>
        <p><i class="fas fa-print mr-3"></i> + 01 234 567 89</p>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Footer links -->

    <hr />

    <!-- Grid row -->
    <div class="row d-flex align-items-center">
      <!-- Grid column -->
      <div class="col-md-7 col-lg-8">
        <!--Copyright-->
        <p class="text-center text-md-left">
          © 2020 Copyright:
          <a href="https://mdbootstrap.com/">
            <strong>Sarvoday Foundation</strong>
          </a>
        </p>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-5 col-lg-4 ml-lg-0">
        <!-- Social buttons -->
        <div class="text-center text-md-right">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item">
              <a class="btn-floating btn-sm rgba-white-slight mx-1">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn-floating btn-sm rgba-white-slight mx-1">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn-floating btn-sm rgba-white-slight mx-1">
                <i class="fab fa-google-plus-g"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn-floating btn-sm rgba-white-slight mx-1">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->
</footer>
<!-- Footer -->
