<section>
    <div id="demo" class="carousel slide" data-ride="carousel">
        
        <!-- The slideshow -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="assets/img/Sarcodya_our_work5.jpg" alt="Get Involved" width="100%" height="490px">
          </div>
          <div class="carousel-item">
            <img src="assets/img/Sarcodya_our_work6.jpg" alt="Get Involved" width="100%" height="490px">
          </div>
          <div class="carousel-item">
            <img src="assets/img/Sarcodya_our_work7.jpg" alt="Get Involved" width="100%" height="490px">
          </div>
        </div>
        
        <!-- Left and right controls -->
        <a class="carousel-control-prev" href="#demo" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#demo" data-slide="next">
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
</section>
<br>
<section>
    <div class="container get_involved2">
        <div class="row">
            <div class="col-md-3 cust">
                 <div class="block_inner">
                     <div class="cust_icon">
                        <i class="fas fa-user-plus"></i>                 
                     </div>
                     <div class="cust_heading">
                         <h2 class="cust_head">Volunteer</h2>
                     </div>
                     <div class="sub_par">
                        <h6 class="sub_para">Anyone, who thinks can make a difference, can be a part. </h6>
                     </div>
                 </div>
            </div>
            <div class="col-md-3 custs">
                <div class="block_inner">
                    <div class="cust_icon">
                        <i class="fa fa-map-marker" aria-hidden="true"></i>                
                     </div>
                    <div class="cust_heading">
                        <h2 class="cust_head">Choose a cause</h2>
                    </div>
                    <div class="sub_par">
                       <h6 class="sub_para">Based on your interest, you can choose the cause that you wish to support. </h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 cust">
                <div class="block_inner">
                    <div class="cust_icon">
                        <i class="fa fa-comments-o" aria-hidden="true"></i>               
                     </div>
                    <div class="cust_heading">
                        <h2 class="cust_head">Serve</h2>
                    </div>
                    <div class="sub_par">
                       <h6 class="sub_para"> Be a part of the cause of your liking and make a positive change. </h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 custs">
                <div class="block_inner">
                    <div class="cust_icon">
                        <i class="fa fa-heart-o" aria-hidden="true"></i>                
                     </div>
                    <div class="cust_heading">
                        <h2 class="cust_head">Spread Love </h2>
                    </div>
                    <div class="sub_par">
                       <h6 class="sub_para">Joy and happiness are contagious. Help us spread it to places where it is needed. </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br>
<!-- Second Step -->

<section>
    <div class="container get_involved3">
        <div class="row paad">
            <div class="col-md-4">
                <div class="one_third">
                    <div class="icon_box">
                        <i class="icones fa fa-book" aria-hidden="true"></i>
                        <div class="icon_box_inner">
                            <h3 class="box_heading">Mission</h3>
                            <div class="icon_box_text">
                                <p>Our mission is to cater to as many different social causes as possible so that different volunteers cater to different causes&nbsp;in parallel.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="one_third">
                    <div class="icon_box">
                        <i class="icones fa fa-eye" aria-hidden="true"></i>
                        <div class="icon_box_inner">
                            <h3 class="box_heading">Vision</h3>
                            <div class="icon_box_text">
                                <p>Our vision is to make everyone a part of some social initiative so that there is positive and sustainable change in our society.                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="one_third">
                    <div class="icon_box">
                        <i class="icones fas fa-wallet" aria-hidden="true"></i>
                        <div class="icon_box_inner">
                            <h3 class="box_heading">Donate</h3>
                            <div class="icon_box_text">
                               <p>We do not take cash donations. We take the things directly which are required instead. Thus keeping the process transparent.                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
        <div class="row paad">
            <div class="col-md-4">
                <div class="one_third">
                    <div class="icon_box">
                        <i class="icones fa fa-diamond" aria-hidden="true"></i>
                        <div class="icon_box_inner">
                            <h3 class="box_heading">Values</h3>
                            <div class="icon_box_text">
                                <p>We thrive on transparency and sustainable changes. We need support of like minded individuals to make positive change in society.                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="one_third">
                    <div class="icon_box">
                        <i class="icones fa fa-magic" aria-hidden="true"></i>
                        <div class="icon_box_inner">
                            <h3 class="box_heading">Belief</h3>
                            <div class="icon_box_text">
                                <p>We believe that if everyone of us spares few hours every month, earth will be a better place to live in. We need your support to bring about positive change.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="one_third">
                    <div class="icon_box">
                        <i class="icones fas fa-user-friends" aria-hidden="true"></i>
                        <div class="icon_box_inner">
                            <h3 class="box_heading">Group</h3>
                            <div class="icon_box_text">
                                <p>We are a group of people with different interests but work together for the betterment of the society. Do join us to make our initiatives successful.                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </div>
    </div>
</section>

<div class="container get_involved">
    <div class="heading">
        <h2 class="head">Get Involved</h2>
    </div>
</div>
<section class="get_involved1">
    <div class="container">
        <div class="inv_content">
            <div class="row">
                <div class="col-md-3">
                    <a href="#">
                        <h4> <img src="assets/img/get-involved-i-01.png" alt="get-involved"> Donate </h4>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="#">
                        <h4> <img src="assets/img/get-involved-i-02.png" alt="get-involved">  Sign Up </h4>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="#">
                        <h4> <img src="assets/img/get-involved-i-03.png" alt="get-involved"> Spread the Word </h4>
                    </a>
                </div>
                <div class="col-md-3">
                    <a href="#">
                        <h4> <img src="assets/img/get-involved-i-04.png" alt="get-involved"> Contact Us </h4>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>