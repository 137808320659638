<div class="site-logo__shape">
  <svg
    width="1920px"
    height="1690px"
    class="shape"
    viewBox="0 0 1920 1690"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="fill-grey"
      d="M107.031481,400.759434 C39.8437707,448.856201 0,526.310447 0,608.818959 L0,1064.32167 C0,1146.83241 39.8437707,1224.28443 107.031481,1272.38342 L599.768862,1625.1294 C682.813336,1684.58102 793.297661,1689.19685 881.032916,1636.88263 L1795.00615,1091.9165 C1872.53008,1045.69144 1920,962.212577 1920,872.099296 L1920,801.041336 C1920,710.930281 1872.53008,627.451421 1795.00615,581.226357 L881.032916,36.2602278 C793.297661,-16.0539923 682.813336,-11.4381627 599.768862,48.0134566 L107.031481,400.759434 Z"
    ></path>
  </svg>
  <span class="logo">
    <a routerLink="Home">
      <img src="assets/img/logo-main.png" class="avatar-01" />
      <!-- <div class="txt">
        <span class="txt1">
          SARVODAY<span>&nbsp;</span><span class="txt2">FOUNDATION</span></span
        >
      </div> -->
    </a>
  </span>
</div>
<mat-toolbar class="top-nav">
  <mat-toolbar-row>
    <!-- <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
      <mat-icon>menu</mat-icon>
    </button> -->
    <span class="example-spacer"></span>
    <span class="one" routerLink="Home">HOME</span>

    <span class="one" routerLink="about">ABOUT US</span>

    <span class="one" routerLink="ourwork">OUR WORK </span>

    <span class="one" routerLink="outreach">OUTREACH </span>

    <span class="one" routerLink="funding">FUNDING </span>

    <span class="one" routerLink="gallery">GALLERY</span>

    <span class="one" routerLink="getinvolved">GET INVOLVED </span>

    <span class="last one" routerLink="contactUs">CONTACT US</span>
    
    <span class="dropdown">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <!-- <mat-icon>more_vert</mat-icon> -->
        <mat-icon>dialpad</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <span routerLink="Home">HOME</span>
        </button>
        <button mat-menu-item >
          <span routerLink="about">ABOUT US</span>
        </button>
        <button mat-menu-item>
          <span routerLink="ourwork">OUR WORK</span>
        </button>
        <button mat-menu-item>
          <span routerLink="outreach">OUTREACH</span>
        </button>
        <button mat-menu-item>
          <span  routerLink="funding">FUNDING</span>
        </button>
        <button mat-menu-item>
          <span routerLink="gallery">GALLERY</span>
        </button>
        <button mat-menu-item>
          <span routerLink="getinvolved">GET INVOLVED</span>
        </button>
        <button mat-menu-item>
          <span routerLink="contactUs">CONTACT US</span>
        </button>
      </mat-menu>
    </span>
   
  </mat-toolbar-row>
</mat-toolbar>
