<img src="assets/img/gallery4.jpg" class="img-gallery-top" alt="image1" width="100%" height="500">

<br /><br /><br/>
<div class="card gallery-heading">
    <h2> Pictures Of Sarvoday Foundation</h2>
</div>

<br /><br/>
<div class="row">
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-1">
                <img src="assets/img/gallery1.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-1">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery1.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-2">
                <img src="assets/img/gallery2.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-2">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery2.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-3">
                <img src="assets/img/gallery3.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-3">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery3.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>

</div>


<br /><br/>
<div class="row">
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-4">
                <img src="assets/img/gallery4.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-4">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery4.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-5">
                <img src="assets/img/gallery5.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-5">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery5.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-6">
                <img src="assets/img/gallery6.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-6">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery6.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>

</div>

<br /><br/>
<div class="row">
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-7">
                <img src="assets/img/gallery7.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-7">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery7.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-8">
                <img src="assets/img/gallery8.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-8">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery8.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-9">
                <img src="assets/img/gallery9.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-9">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery9.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>

</div>

<br /><br/>
<div class="row">
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-10">
                <img src="assets/img/gallery10.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-10">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery10.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-11">
                <img src="assets/img/gallery11.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-11">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery11.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-12">
                <img src="assets/img/gallery12.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-12">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery12.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>

</div>

<br /><br/>
<div class="row">
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-13">
                <img src="assets/img/gallery13.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-13">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery13.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-14">
                <img src="assets/img/gallery14.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-14">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">


                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery14.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>
    <div class="col-md-4">
        <div class="container">
            <div class="card" data-toggle="modal" data-target="#myModal-15">
                <img src="assets/img/gallery15.jpg" class="img-about img-fluid" alt="image1" width="100%" height="auto">
            </div>


            <div class="modal fade" id="myModal-15">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Sarvoday Foundation</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>


                        <div class="modal-body">
                            <img src="assets/img/gallery15.jpg" class="gallery-body" alt="image1" width="100%"
                                height="auto">
                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>

</div>