<!-- IMAGE TOP -->
<section class="our_work">
    <img src="assets/img/Sarvodya_our_work3.jpg" alt="" width="100%" height="auto">
    <div class="overlay">
        OUR WORK
    </div>
</section>
<br>

<!-- Our work Content -->
<section class="our_work1">
    <div class="container count_lg">
        <h3 class="ab">Sarvoday sikhsha abhiyan</h3>
        <p>Sarvoday sikhsha abhiyan free of cost compulsory primary education to all the children’s from age 3yrs till
            they achieve the age of 10yrs is provided. We have initiated this task in rural areas of Bihar, Uttar
            Pradesh, Jharkhand and slum area of MUMBAI and with increasing funding more areas will be covered.</p>
    </div>
</section>
<br>
<section class="our_work2">
    <div class="container count_lg">
        <div class="row mar">
            <div class="col-md-7">
                <p>You can sponsor a child for their education. There have been working professional who are
                    contributing on monthly basis such as Rs.1ooo/month ;Rs. 5000/month for sponsoring children for
                    their food, education and other necessities. Brief background of the child being sponsored is
                    communicated along with weekly, monthly and yearly performance of the child in academics and social
                    life </p>
            </div>
            <div class="col-md-5">
                <img src="assets/img/Sarcodya_our_work5.jpg" alt="" width="100%" height="300px">
            </div>
        </div>
        <div class="row mar">
            <div class="col-md-5">
                <img src="assets/img/Sarcodya_our_work6.jpg" alt="" width="100%" height="300px">
            </div>
            <div class="col-md-7">
                <p>They are provided with all books, notebooks, school dress and food as mid-day meal. These children
                    coming from very deprived families who in lack of food or source of livelihood of parents are forced
                    to work resulting in harsh child labour conditions or beg on road or village. Education being a
                    fundament right Is of paramount importance which can transform their fate. To save their future the
                    trust organise their classes on rented premises and provide them with food and care facilities. The
                    trust is trying to raise funds with aid of administration so that various government schemes
                    directed toward child welfare can be utilized to their full potential.</p>
            </div>
        </div>
    </div>
</section>
<br>

<section class="our_work2 k_v">
    <div class="container count_lg">
        <h3 class="ab ab-s">SARVODAY KAUSHAL VIKASH ABHIYAN</h3>
        <p>It has been found that families who don’t have source of earning for even food they cant afford education for
            their children. They force their children to beg or work. In order to secure the future of children, the
            livelihood of their parents is needed to be insured. </p>
        <span class="sub_head">A man with skill can never be unemployed :</span>
        <p>With this motto the organisation has set up skill centres and skill the illiterates works like pottery making
            with cement and sand which are more durable and are in trend for roof top farming & kitchen gardening this
            has helped hundreds of people to earn their own livelihood from the sale of pottery.</p>
        <div class="row paad">
            <div class="col-md-7">
                <span class="sub_head">Computer & digital training :</span>
                <p>People are being provided with laptop and keyboard to improve their typing, knowledge of printing
                    from various data available of various government sources. They are further trained to click quality
                    photograph, edit them, make movies and short videos. Since village people had to travel 20-30 KM to
                    reach some cities for these basic tasks being done, such training at grassroot level along with
                    providing them with equipments has not only saved precious time of many villagers but has also
                    enabled them to earn livelihood through various digital works.</p>
            </div>
            <div class="col-md-5">
                <img src="assets/img/Sarcodya_our_work7.jpg" alt="" width="100%" height="300px">
            </div>
        </div>
        <div class="row paad">
            <div class="col-md-5">
                <img src="assets/img/Sarcodya_our_work8.jpg" alt="" width="100%" height="300px">
            </div>
            <div class="col-md-7">
                <span class="sub_head">Engineering works :</span>
                <p>Skill related to works of assembling electric bulb, electrical fittings, plumbing, constructions
                    works and many other engineering work skill is being imparted to the unemployed youth of the
                    village. This has enabled us to create a group of plumbers, electricians and construction workers
                    who are ready to serve in cities and big towns where there is dearth of such service providers.</p>
            </div>
        </div>
        <div class="row paad">
            <div class="col-md-7">
                <span class="sub_head">Stitching & weaving :</span>
                <p>Women of the household are trained with variety of fabric work stitching handloom stuffs like home
                    decoration works (doormats, designer pillows, knitting, festive lamps and other handicrafts
                    materials) which are later on sold in local market as well as online. This has helped immensely in
                    improving daily wage earned by women of the household leading to women empowerment on many fronts.
                </p>
            </div>
            <div class="col-md-5">
                <img src="assets/img/Sarvodya_our_work4.jpg" alt="" width="100%" height="300px">
            </div>
        </div>
        <div class="row paad">
            <div class="col-md-5">
                <img src="assets/img/Sarvodya_our-work2.jpg" alt="" width="100%" height="300px">
            </div>
            <div class="col-md-7">
                <span class="sub_head">Culinary activities : </span>
                <p>For making marginal women empowered we started the initiative of cooking classes where women are
                    trained free to learn the varieties of food cuisines from different states so that in near future
                    they will be encouraged to open street stalls on daily basis.</p>
            </div>
        </div>
    </div>
</section>
<br>

<section class="our_work3">
    <div class="container count_lg">
        <h3 class="ab ab-s">SARVODAY GRAM VIKASH ABHIYAN</h3>
        <span class="sub_head">Tree plantation & horticulture:</span>
        <div class="row">
            <div class="col-md-7">
                <p>Since agriculture has been the main source of livelihood it is more appealing in terms of skill
                    imparting to rural population, however the traditional agriculture is neither sustainable in long
                    run nor profitable. So the trust has started allotting common land to different landless farmers to
                    plant trees which can be economically sold when they mature. Farmers are being trained for
                    horticulture.</p>
                <p>There are many areas which we are exploring for increasing avenues for earning to sustain the
                    livelihood which will ultimately help in doubling the rural income in near furture.</p>
                <p>Only limitation which our organisation is suffering from is the lack of money. So it’s a sincere
                    request for the donation of even Rs.1/- from you which will help us to bring the change we want to
                    see around.</p>
            </div>
            <div class="col-md-5">
                <img src="assets/img/Sarvodya_our-work1.jpg" alt="" width="100%" height="300px">
            </div>
        </div>
    </div>
</section>
<br>
<section class="our_work2 k_v card_our">
    <div class="container count_lg">
        <h3 class="ab ab-s" style="text-align: center;">OUR PROGRAMMES</h3>
        <div class="row paad">
            <div class="col-md-3">
                <div class="card">
                    <span class="sub_head1">Education</span>
                    <img src="assets/img/gallery10.jpg" alt="">
                    <p>Mission Education, the education programme of Sarvoday Foundation provides basic education and
                        healthcare to underprivileged children, with the belief that education is both the means as well
                        as the end to a better life because it empowers an individual to earn his/her livelihood while
                        also increasing one's awareness on a range of issues; in the process helping him/her evolve as a
                        better citizen.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <span class="sub_head1">Healthcare</span>
                    <img src="assets/img/gallery10.jpg" alt="">
                    <p>Sarvoday on Wheels is a national level mobile healthcare programme which provides curative and
                        preventive healthcare services at the doorsteps of the underserved in remote rural villages and
                        urban slums, besides inculcating a health-seeking behavior in them, which also includes child
                        health programmes in schools.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <span class="sub_head1">Livelihood</span>
                    <img src="assets/img/gallery10.jpg" alt="">
                    <p>Sarvoday Twin e-Learning Programme (STeP) evolved as a logical linkage to Mission Education, based
                        on the need to provide employability skills to high school pass-outs and urban underprivileged
                        youth to enhance their prospects of employment in the fast expanding retail, hospitality and
                        service sectors.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <span class="sub_head1">Women Empowerment</span>
                    <img src="assets/img/gallery10.jpg" alt="">
                    <p>Swabhiman is a programme focusing on girl children and women empowerment. The USP of Swabhiman is
                        its innovative and highly effective approach named the ‘4-S Model’ which means Seeking
                        Healthcare as a Behaviour, Support through Education, Support from Men through Male Involvement,
                        and Sustaining Change in the Community.</p>
                </div>
            </div>
        </div>
        <div class="row paad">
            <div class="col-md-3">
                <div class="card">
                    <span class="sub_head1">Empowering Grassroots</span>
                    <img src="assets/img/gallery10.jpg" alt="">
                    <p>The growth of the non-profit sector in India, in the last two decades, has been phenomenal.
                        Grassroots NGOs particularly, by engaging directly with the people, are able to participate in
                        the thought-making process of the communities they work with, and thus have the capacity to
                        bring about long-lasting positive change.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <span class="sub_head1">Child for Child</span>
                    <img src="assets/img/gallery10.jpg" alt="">
                    <p>Children are the future of a nation. They are the best change agents, be it in the family or the
                        community in which they live. It is therefore crucial to catch them young and inculcate in them
                        feelings of empathy and conscience so that they grow up as responsible individuals.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <span class="sub_head1">Special Support Programme</span>
                    <img src="assets/img/gallery10.jpg" alt="">
                    <p>Sarvoday Foundation constantly strives to support and empower individuals, particularly
                        underprivileged children enabling them to meet basic ambitions of their life and realize their
                        potential, make informed choices, resist oppression, fight for rightful existence and facilitate
                        new possibilities and opportunities for them.</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <span class="sub_head1">Disaster Response</span>
                    <img src="assets/img/gallery10.jpg" alt="">
                    <p>Being a disaster-prone country, India is highly vulnerable to various types of natural calamities
                        due to its geographical positioning. According to the National Disaster Management Authority,
                        Govt. of India, more than 58.6% of the country’s landmass is prone to earthquakes and over 12%
                        is prone to floods. Smile Foundation has provided disaster relief in many such disasters.</p>
                </div>
            </div>
        </div>
    </div>
</section>