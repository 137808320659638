
<app-nav-bar></app-nav-bar>

<router-outlet (activate)="onActivate($event)"></router-outlet>

<br>
<app-footer></app-footer>




