<div class="img">
    <img src="assets/img/contactus.jpg" alt="contact" width="100%">
</div>

<br>
<div class="thm-container">
    <div class="row">
        <div class="col-md-8">
            <div class="contact-form-content">
                <div class="title">
                    <span>Contact with us</span>
                    <h2>Send Message</h2>
                </div><!-- /.title -->
                <form action="inc/sendemail.php" class="contact-form" novalidate="novalidate">
                    <input type="text" name="name" placeholder="Your full name">
                    <input type="text" name="email" placeholder="Your email address">
                    <textarea name="message" placeholder="What you are looking for?"></textarea>
                    <button type="submit" class="thm-btn yellow-bg">Submit Now</button>
                    <div class="form-result"></div><!-- /.form-result -->
                </form>
            </div><!-- /.contact-form-content -->
        </div><!-- /.col-md-8 -->
        <div class="col-md-4">
            <div class="contact-info text-center">
                <div class="title text-center">
                   <span>Contact info</span>
                   <h2>Details</h2>
                </div><!-- /.title -->
                <div class="single-contact-info">
                    <h4>Address</h4>
                    
                    <p> Plot no:420, village:dharampur, <br> GAYA, BIHAR-82420, INDIA</p>
                </div><!-- /.single-contact-info -->
                <div class="single-contact-info">
                    <h4>Phone</h4>
                    <p>Local: 222 999 888 <br> Mobile: 000 8888 999</p>
                </div><!-- /.single-contact-info -->
                <div class="single-contact-info">
                    <h4>Email</h4>
                    <p>needhelp@printify.com <br> inquiry@printify.com</p>
                </div><!-- /.single-contact-info -->
                <div class="single-contact-info">
                    <h4>Follow</h4>
                    <div class="social">
                       <a href="#" class="fab fa-twitter hvr-pulse"></a><!--  
                        --><a href="#" class="fab fa-pinterest hvr-pulse"></a><!--  
                        --><a href="#" class="fab fa-facebook-f hvr-pulse"></a><!--  
                        --><a href="#" class="fab fa-youtube hvr-pulse"></a>
                   </div><!-- /.social -->
                </div><!-- /.single-contact-info -->
            </div><!-- /.contact-info -->
        </div><!-- /.col-md-4 -->
    </div><!-- /.row -->
</div>

<br>

<div class="map-box">
    <iframe src="https://maps.google.com/maps?q=gaya&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
   </div>

